import React, { useEffect } from 'react'

import { RouteComponentProps } from '@reach/router'
import { IGatsbyImageData } from 'gatsby-plugin-image'
import tw from 'twin.macro'

import SEO from 'components/shared/seo'
import { useLocations } from 'content-queries/mx/new/locations'
import { usePartnerLocations } from 'content-queries/partners/partners'
import fireEvent from 'context/tracking/events'
import { MainContent } from 'mx/components/new'
import Layout from 'mx/layout'
import FilterLayout from 'mx/partners/filter-layout'
import PartnerItemCard from 'mx/partners/partner-item-card'

export interface PartnerItem {
  address: {
    address: string
  }
  city: string
  companyName: string
  cover: {
    title?: string
    description: string
    localFile: {
      childImageSharp: {
        gatsbyImageData: IGatsbyImageData
      }
    }
  }
  description: { description: string }
  region: string
  publishingUrl?: string
}

const LocationsPage = (_: RouteComponentProps) => {
  const { data: items } = usePartnerLocations()
  const { data } = useLocations()
  useEffect(() => {
    document.body.scrollTo({ top: 0 })
    fireEvent({
      type: 'page_viewed',
      pageTitle: 'Locations',
    })
  }, [])

  const partnersCollection = (items as PartnerItem[]).map((n: PartnerItem) => ({
    ...n,
  }))
  const [
    {
      node: {
        sectionModules: [hero],
      },
    },
  ] = data

  return (
    <Layout>
      <MainContent header darkCTA data={hero} classname={tw`mb-12`} />
      <FilterLayout items={partnersCollection}>
        {({ filteredItems }) => (
          <>
            <PartnerItemCard items={filteredItems} />
          </>
        )}
      </FilterLayout>
    </Layout>
  )
}

export default LocationsPage

export const Head = () => {
  const { data } = useLocations()
  const [
    {
      node: {
        image,
        meta: { title, description },
      },
    },
  ] = data
  return (
    <SEO
      description={description}
      path="/locations"
      title={title}
      image={
        image.localFile?.childImageSharp?.gatsbyImageData?.images?.fallback?.src
      }
    />
  )
}

import React, { useState } from 'react'

import 'twin.macro'
import { navigate } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import tw from 'twin.macro'

import { WebsiteButton as Button } from 'mx/components/section-components'
import { PartnerItem } from 'pages/locations'
import { sanitizeUrl } from 'views/utils'

type PartnersProps = {
  items: PartnerItem[]
}
const PartnerItemCard = ({ items }: PartnersProps) => {
  const itemsPerPage = 6
  const lastPage = (items.length || 0) / itemsPerPage
  const [page, setPage] = useState(1)

  const loadMore = () => {
    setPage(page + 1)
  }
  return (
    <div tw="w-full max-w-screen-xl h-full max-h-full px-4 mb-20 lg:mb-32 mt-10 lg:mt-14 mx-auto">
      {items.length > 0 && (
        <>
          <div tw="grid grid-cols-1 gap-x-16 lg:gap-x-24 w-full  md:grid-cols-2 h-max justify-items-center">
            {items.slice(0, itemsPerPage * page).map((item) => (
              <div
                key={item.companyName}
                tw="max-w-xl flex-col items-center bg-gray-light inline-flex my-6 md:my-12"
              >
                <div tw="relative w-full h-72 lg:h-96">
                  <GatsbyImage
                    css={tw`absolute inset-0 w-full h-full`}
                    imgStyle={{
                      objectFit: 'cover',
                      objectPosition: 'center',
                    }}
                    title={item.cover?.title || ''}
                    alt={item.cover?.description || ''}
                    image={
                      item.cover?.localFile?.childImageSharp?.gatsbyImageData
                    }
                  />
                </div>

                <div tw="self-stretch p-9 md:p-10 lg:p-12 xl:p-24 flex flex-col gap-8">
                  <div tw="flex justify-between items-center leading-relaxed text-base font-normal py-3 max-w-sm">
                    <p tw="text-brown-500 h-8 w-32 leading-relaxed bg-brown-medium text-gray-light rounded-full text-center content-center">
                      {item.region}
                    </p>
                    <p tw="text-gray-dark text-lg font-thin capitalize">
                      {item.city}
                    </p>
                  </div>
                  <div>
                    <h2 tw="text-gray-dark text-2xl font-thin leading-tight">
                      {item.companyName}
                    </h2>
                    <p
                      tw="text-brown-medium pt-4"
                      dangerouslySetInnerHTML={{
                        __html: item.address.address ?? '',
                      }}
                    />
                  </div>

                  <div
                    tw="text-base text-gray-dark leading-normal font-light overflow-hidden text-ellipsis"
                    css={{
                      display: '-webkit-box',
                      WebkitBoxOrient: 'vertical',
                      WebkitLineClamp: 5,
                    }}
                    dangerouslySetInnerHTML={{
                      __html: item.description.description ?? '',
                    }}
                  />

                  <Button
                    tw="pt-4"
                    color="transparentBlack"
                    rounded
                    onClick={() =>
                      navigate(
                        `/locations/${
                          item.publishingUrl || sanitizeUrl(item.companyName)
                        }`,
                      )
                    }
                  >
                    Take a Look
                  </Button>
                </div>
              </div>
            ))}
          </div>

          {page < lastPage && (
            <div tw="flex justify-start my-6">
              <Button color="solidDark" rounded onClick={loadMore}>
                View More
              </Button>
            </div>
          )}
        </>
      )}
    </div>
  )
}
export default PartnerItemCard

import { graphql, useStaticQuery } from 'gatsby'

export const usePartnerLocations = () => {
  const { content } = useStaticQuery(graphql`
    query {
      content: allContentfulPartnerLocation(
        sort: { fields: companyName, order: ASC }
      ) {
        data: nodes {
          id
          address {
            address
          }
          city
          companyName
          description {
            description
          }
          publishingUrl
          cover {
            title
            description
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          region
        }
      }
    }
  `)
  return content
}

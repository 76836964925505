import React, { useEffect, useState } from 'react'

import tw from 'twin.macro'

import { PartnerItem } from 'pages/locations'

type RenderCallbackArgs = {
  filteredItems: PartnerItem[]
}

type FilterLayoutProps = {
  children: (args: RenderCallbackArgs) => React.ReactNode
  items: PartnerItem[]
}

const FilterLayout = ({ children, items }: FilterLayoutProps) => {
  const [selectedRegion, setSelectedRegion] = useState<string | null>(null)
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [filteredItems, setFilteredItems] = useState<PartnerItem[]>(items)

  const regions = Array.from(
    new Set(items.map((item: PartnerItem) => item.region)),
  )

  const handleRegionChange = (region: string | null) => {
    setSelectedRegion(region)

    const filtered = region
      ? items.filter((item: PartnerItem) => item.region === region)
      : items

    setFilteredItems(filtered)
  }

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const query = e.target.value.toLowerCase()
    setSearchTerm(query)
    handleRegionChange(null)

    const filtered = items.filter(
      (item: PartnerItem) =>
        item.companyName.toLowerCase().includes(query) ||
        item.region.toLowerCase().includes(query) ||
        item.description.description.toLowerCase().includes(query) ||
        item.city.toLowerCase().includes(query),
    )
    setFilteredItems(filtered)
  }

  useEffect(() => {
    handleRegionChange(selectedRegion)
  }, [items, selectedRegion])

  return (
    <>
      <div tw="flex flex-col md:flex-row gap-x-16 gap-y-6 my-16 relative max-w-screen-xl mx-auto px-4 md:px-8 xl:px-6">
        <div tw="flex flex-row space-x-6 md:space-x-9 !m-0 w-11/12 md:w-9/12 items-center">
          <p tw="text-lg md:text-xl lg:text-2xl text-gray-dark">Search:</p>
          <div tw="flex items-center space-x-5 bg-white w-full max-w-72 border-b border-b-gray-dark rounded-sm">
            <input
              placeholder="City, region, name, etc."
              onChange={handleSearch}
              value={searchTerm}
              tw="bg-transparent text-base block px-0 py-2 w-full border-0 placeholder:(text-sm text-gray-300) focus:(border-0 ring-0) focus-visible:(border-0 ring-0 outline-none)"
            />
          </div>
        </div>
      </div>

      <div tw="flex flex-wrap justify-evenly md:justify-start items-center space-x-6 mt-16 md:mt-24 px-4 md:px-8 xl:px-4 max-w-screen-xl mx-auto">
        <h3
          tw="h-11 max-w-36 px-10 text-gray-dark hover:text-white text-base rounded-full mt-8 bg-gray-light hover:bg-gray-dark place-content-center text-center"
          css={[selectedRegion === null && tw`!bg-gray-dark !text-white`]}
          onClick={() => handleRegionChange(null)}
        >
          All
        </h3>
        {regions.map((region) => (
          <h3
            key={region}
            tw="h-11 max-w-44 min-w-32 px-5 text-gray-dark hover:text-white text-base rounded-full mt-8 !ml-0 md:!ml-6 bg-gray-light hover:bg-gray-dark  place-content-center text-center"
            css={[selectedRegion === region && tw`!bg-gray-dark !text-white`]}
            onClick={() => handleRegionChange(region ?? null)}
          >
            {region}
          </h3>
        ))}
      </div>
      <div>{children({ filteredItems })}</div>
    </>
  )
}

export default FilterLayout
